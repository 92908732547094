/**
 * 
 * 쿠키를 저장한다.
 * 
 * @param {*} k 
 * @param {*} v 
 */
export const setCookie = (k, v) => {
    v = encodeURIComponent(v);
    document.cookie = k + "=" + v + "; max-age=" + 60 * 60 * 24 * 365 * 100 + "; path=/";
};

export const deleteCookie = (k) => {
    document.cookie = k + "=; path=/;";
};

/**
 * 쿠키 내보내기.
 * @param {d} k 
 * @param {*} v 
 * @returns 
 */
export const getCookie = (key) => {

    let cookie = {};

    document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = !v ? null : decodeURIComponent(v);
    });

    const str = cookie[key] ? decodeURIComponent(cookie[key]) : '';
    
    return str; 

};
