import { getCookie } from "@/js/utils/CookieUtil";
import store from '@/js/store'; // Vuex store를 가져옴
import axios from "axios";
import GmxGlobal from "@/js/global";

import router from '@/router';

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;

//****Back-end JwtInterceptor와 일치해야 할 것.
const RES_CODE = {
    CLEAR : '1001', // 토큰이 만료된 경우
    JWT_NOTEQ : '1002', // 기타 JWT 검증 실패 시
    HEADER_NONE : '1003',// 헤더 정보가 없는 경우
    REFRESH : '1000',// Access 재발급.
    UNAUTHORIZED:401
}

let refreshSubscribers = [];

// axios 인스턴스 생성
const axiosInstance = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    },
    withCredentials: true,
});


// JWT 토큰을 위한 설정1
axiosInstance.interceptors.request.use(
    function (config) {
        //Store에서 Access Token 가져오기.
        const token = store.getters['systemInfo/getToken'];
        
        if (token && token != '') {
            //헤더에 탑재
            config.headers['Authorization'] = `Bearer=${token}`;
        }
        
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// JWT 토큰을 위한 설정2
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { config, response: { headers } } = error;
        const originalRequest = config;
        const GmxStatus = headers['gmx-status'];
        
        if ( GmxStatus && GmxStatus == RES_CODE.REFRESH) {
            let isRefreshing = false;
       
            if (!isRefreshing) {
                isRefreshing = true;
                
                axios.post("/esupport-api/getAccessToken", {
                    refreshToken: getCookie('refresh_token')
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    //신규 Access Token 발급.
                    const newToken = response.data.result.accessToken;
                    store.dispatch('systemInfo/setToken', newToken);
     
                    isRefreshing = false;
                    onRefreshed(newToken);
                    refreshSubscribers = [];
                  }).catch(error => {
                    isRefreshing = false;
                    const linkChk = new URLSearchParams(window.location.search).get('link');
                    if ( linkChk ) {
                       const path = window.location.pathname;
                       const param = new URLSearchParams(window.location.search).get('id');
                        router.push({ path: GmxGlobal.LOGIN_PAGE, state: { linkUrl: path, linkId: param} });
                    } else {
                        router.push({ path: GmxGlobal.LOGIN_PAGE});
                    }
                    // 리프레시 토큰도 만료된 경우 로그아웃 처리 등 추가 로직 필요
                     //  window.location = '/ezone/login?link=1'; // 리프레시 토큰 만료 시 로그인 페이지로 리다이렉트
              //      console.log('Refresh token expired');
                    console.error('Error respons e:', error.response);

                  });
            }

            return new Promise((resolve) => {
                addRefreshSubscriber(token => {
                    
                    originalRequest.headers['Authorization'] = `Bearer=${token}`;
                    resolve(axiosInstance(originalRequest));
                });
            });
        }

        return Promise.reject(error);
    }
);

const onRefreshed = token => {
    refreshSubscribers.map(callback => callback(token));
}

const addRefreshSubscriber = callback => {
    refreshSubscribers.push(callback);
}

// 기존 Request 함수 수정
const load = (url, opt, _callBack) => {

    const prop = {
        method: opt.method || "get",
        url: url,
        headers: opt.header || { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", 'Authorization': `Bearer=${  store.getters['systemInfo/getToken'] }`, },
        params: opt.param,
        responseType: "json",
        withCredentials: true,
    };

    if (prop.method !== 'get') {
        prop['data'] = opt.param;
        delete prop.params;
     //   prop['params'] = decodeURI(opt.param);
    }
    //console.log(decodeURI(opt.param));

    axiosInstance(prop)
        .then(function (response) {
            _callBack(response);
        })
        .catch(function (error) {
            console.log("error: " + error);
            _callBack(error);
        });
};

export default {
 load
};