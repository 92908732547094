/**
 * 모든 명칭 처리는 해당 파일에서 관리하시죠.
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 *  ********************
 * 
 *  검색 해보고 추가하세여!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 
 * 
 * 
 */

//현재는 의미 없을수도.
const getLangTxt = (txt) => {

    return txt;
}

export const SYSTEM_NAME_HANA = getLangTxt('e-지원');
export const MODULE_NAME_HOONSICK = getLangTxt('현황 관리');
export const MODULE_NAME_YURIM = getLangTxt('시스템 관리');
export const MODULE_NAME_ARA = getLangTxt('보고서');
export const MODULE_NAME_SEARCH = getLangTxt('통합 검색');

export const MODULE_NAME_JIWOO = getLangTxt('대시보드');
export const DENSOL_COPYRIGHT = getLangTxt('Copyright © GEOMEXSOFT.');

export const MENU_SALES = getLangTxt('영업현황 관리');
export const MENU_PROJECT = getLangTxt('프로젝트 관리');
export const MENU_ORDERS = getLangTxt('수주계약 관리');
export const MENU_OUTS = getLangTxt('외주계약 관리');
export const MENU_BIZ = getLangTxt('유지보수현황');
export const MENU_MYPAGE = getLangTxt('마이페이지');

export const MENU_SEARCH = getLangTxt('검색창');

export const MENU_USER = getLangTxt('사용자 관리');
export const MENU_AUTH = getLangTxt('권한 관리');
export const MENU_HISTORY = getLangTxt('사용자 접속 기록 관리');
export const MENU_COMPANY = getLangTxt('거래처 관리');
export const MENU_CLIENT = getLangTxt('발주처 관리');
export const MENU_SOLUTION = getLangTxt('솔루션관리');
export const MENU_TARGETSALES = getLangTxt('매출목표 관리');
export const MENU_FILE = getLangTxt('파일 관리');
export const MENU_MAIL = getLangTxt('메일 발송/승인 대상 관리');


export const MENU_REPORT_CONT = getLangTxt('계약현황 보고서');
export const MENU_REPORT_SALES = getLangTxt('영업현황 보고서');
export const MENU_REPORT_BOARD = getLangTxt('결산 대시보드');
export const MENU_MAP_SOL = getLangTxt('솔루션현황');

export const BUTTON_SALES_ADD = getLangTxt('영업현황 등록');
export const BUTTON_PROJ_ADD = getLangTxt('프로젝트 등록');
export const BUTTON_ORDERS_ADD = getLangTxt('수주계약 등록');
export const BUTTON_OUTS_ADD = getLangTxt('외주계약 등록');

// 로그인
export const LOGIN_TEXT = getLangTxt('더 큰 도약을 위한 오늘, 우리는 늘 내일을 준비합니다.');
export const LOGIN_ID = getLangTxt('아이디');
export const LOGIN_PASS = getLangTxt('비밀번호');
export const LOGIN_PASS_CONFIRM = getLangTxt('비밀번호 확인');
export const LOGIN_MEMORY = getLangTxt('아이디 기억하기');
export const LOGIN_MAIN_BTN = getLangTxt('로그인');
export const LOGIN_RESET = getLangTxt('비밀번호 초기화');
export const LOGIN_CHANGE = getLangTxt('비밀번호 변경');
export const LOGIN_PASS_NEW = getLangTxt('새 비밀번호');
export const LOGIN_JOIN = getLangTxt('계정 등록');
export const LOGIN_NAME = getLangTxt('사용자명');
export const LOGIN_GROUP = getLangTxt('사용자 그룹');
export const LOGIN_DEPARTMENT = getLangTxt('부서');
export const LOGIN_POSITION = getLangTxt('직급');
export const LOGIN_EMAIL = getLangTxt('이메일');
export const LOGIN_HINT_ID = getLangTxt('아이디를 입력해주세요.');
export const LOGIN_HINT_PASS = getLangTxt('비밀번호를 입력해주세요.');
export const LOGIN_HINT_PASS_CONFIRM = getLangTxt('비밀번호를 다시 입력해주세요.');
export const LOGIN_HINT_NAME = getLangTxt('사용자명을 입력해주세요.');
export const LOGIN_HINT_POSITION = getLangTxt('직급을 입력해주세요.');
export const LOGIN_HINT_EMAIL = getLangTxt('이메일을 입력해주세요.');
export const LOGIN_ID_CHECK = getLangTxt('중복확인');
export const LOGIN_JOIN_FINAL_TITLE = getLangTxt('계정 등록 완료');
export const LOGIN_JOIN_FINAL_CONTENT = getLangTxt('계정 등록이 완료되었습니다.<br>관리자 승인이 완료된 뒤 로그인 및 사용이 가능합니다.<br>즉시 사용을 원하실 경우 관리자에게 문의해 주세요.<br><br>로그인 페이지로 이동 버튼을 통해<br>로그인이 가능합니다.');
export const LOGIN_FIND_FINAL_TITLE = getLangTxt('비밀번호 초기화 완료');
export const LOGIN_FIND_FINAL_CONTENT = getLangTxt('비밀번호 초기화가 완료되었습니다.<br>로그인 하기 버튼을 클릭하면 로그인 페이지로 이동합니다.<br><br>변경한 비밀번호로 로그인이 불가하거나, 오류가 발생할 경우<br>관리자에게 문의해 주세요.');
export const LOGIN_GO = getLangTxt('로그인 페이지로 이동');
export const LOGIN_FAIL = getLangTxt('아이디 또는 패스워드가 일치하지 않습니다.');

// 팝업 변경이력
export const POPUP_CHANGE_PASSWORD = getLangTxt('비밀번호 변경');
export const POPUP_CHANGE_HISTORY = getLangTxt('변경이력 조회');
export const POPUP_ACCOUNT = getLangTxt('거래처 등록');

// 팝업 발주처 
export const POPUP_CLIENT_SEARCH = getLangTxt('발주처 검색(다중선택)');
export const POPUP_CLIENT_SEARCH_HINT = getLangTxt('검색');

// 팝업 담당자 변경
export const POPUP_USER_CAHNGE = getLangTxt('담당자 변경');
export const POPUP_USER_SEARCH = getLangTxt('담당자 검색');
export const POPUP_USER_SEARCH_HINT = getLangTxt('담당자를 검색하세요');

// 팝업 프로젝트 등록
export const POPUP_PROJECT = getLangTxt('프로젝트 등록');
export const POPUP_PROJECT_SUB_TITLE = getLangTxt('프로젝트 정보 입력');
export const POPUP_PROJECT_SUB_CONTENT = getLangTxt('코드는 저장 시점에 자동 저장됩니다. 프로젝트 정보 입력 후 저장 버튼을 클릭해 주세요.');
export const POPUP_PROJECT_SALECD_SEARCH = getLangTxt('영업 코드');
export const POPUP_PROJECT_SALECD_SEARCH_HINT = getLangTxt('연결할 프로젝트를 검색하세요');
export const POPUP_PROJECT_BUSINESS_GROUP = getLangTxt('사업부 선택');
export const POPUP_PROJECT_CODE = getLangTxt('프로젝트 코드');
export const POPUP_PROJECT_DATE = getLangTxt('프로젝트 기간');
export const POPUP_PROJECT_NAME = getLangTxt('프로젝트명');
export const POPUP_PROJECT_NAME_HINT = getLangTxt('프로젝트명을 입력해 주세요.');
export const POPUP_PROJECT_NAME_ERROR = getLangTxt('외주계약금액은 필수 입력정보입니다.');
export const POPUP_PROJECT_ETC = getLangTxt('비고');

// 팝업 거래처 등록
export const ACCOUNT_DIVISION = getLangTxt('거래처 구분');
export const ACCOUNT_NAME = getLangTxt('거래처명');
export const ACCOUNT_COMPANY_REGIST_NUMBER = getLangTxt('사업자등록번호');
export const ACCOUNT_RATING = getLangTxt('거래처 등급');
export const ACCOUNT_AGENT_NAME = getLangTxt('대표자명');
export const ACCOUNT_COMPANY_CONTACT_INFO = getLangTxt('회사 연락처');
export const ACCOUNT_CONTACT_PERSON_NAME = getLangTxt('담당자명');
export const ACCOUNT_CONTACT_PERSON_INFO = getLangTxt('담당자 연락처');
export const ACCOUNT_CONTACT_PERSON_EMAIL = getLangTxt('담당자 이메일');
export const ACCOUNT_OUTSOURCING_COMPANY = getLangTxt('외주업체 여부');
export const ACCOUNT_VENDOR = getLangTxt('거래처 계좌');
export const ACCOUNT_BILLING_EMAIL = getLangTxt('계산서용 메일');
export const ACCOUNT_FILE_BUTTON_NAME = getLangTxt('파일 첨부');
export const ACCOUNT_HINT_NAME = getLangTxt('거래처 이름을 입력하세요');
export const ACCOUNT_HINT_COMPANY_REGIST_NUMBER = getLangTxt('사업자등록번호를 입력하세요');
export const ACCOUNT_HINT_AGENE_NAME = getLangTxt('거래처 대표자명을 입력해주세요');
export const ACCOUNT_HINT_COMPANY_CONTACT_INFO = getLangTxt('회사 연락처(대표전화)를 입력해주세요');
export const ACCOUNT_HINT_CONTACT_PERSON_NAME = getLangTxt('담당자명을 입력해주세요');
export const ACCOUNT_HINT_CONTACT_PERSON_INFO = getLangTxt('담당자 연락처를 입력해 주세요');
export const ACCOUNT_HINT_CONTACT_PERSON_EMAIL = getLangTxt('담당자 이메일을 입력해 주세요');
export const ACCOUNT_HINT_VENDOR_BANK = getLangTxt('은행명');
export const ACCOUNT_HINT_VENDOR_BANK_NUMBER = getLangTxt('계좌번호를 입력해 주세요');
export const ACCOUNT_HINT_BILLING_EMAIL = getLangTxt('계산서용 메일을 입력해주세요');
export const ACCOUNT_NOT_APPLICABLE = getLangTxt('미해당');
export const ACCOUNT_APPLICABLE = getLangTxt('해당');



// 템플릿 버튼?
export const BUTTON_NAME = getLangTxt('상태 3개월 미변경');
export const BUTTON_DEL = getLangTxt('삭제');
export const BUTTON_UPD = getLangTxt('수정');
export const BUTTON_EXCEL = getLangTxt('엑셀 다운로드');
export const BUTTON_SEARCH = getLangTxt('검색');
export const BUTTON_ADD = getLangTxt('등록');
export const BUTTON_OK  = getLangTxt('확인');
export const BUTTON_CANCEL  = getLangTxt('취소');
export const BUTTON_APPLY = getLangTxt('적용');
export const BUTTON_SAVE = getLangTxt('저장');
export const BUTTON_CLIENT_CAHNGE = getLangTxt('발주처 변경');
export const BUTTON_USER_CHANGE = getLangTxt('담당자 변경');
export const BUTTON_REFLASH = getLangTxt('새로고침');
export const BUTTON_MAP_ON = getLangTxt('지도화면켜기');
export const BUTTON_MAP_OFF = getLangTxt('지도화면끄기');


// 리스트
export const LIST_UNIT = getLangTxt('건');
export const LIST_UNIT2 = getLangTxt('백만원');
export const LIST_UNIT3 = getLangTxt("원");
export const LIST_STATE1 = getLangTxt('전체');
export const LIST_STATE2 = getLangTxt('진행중');
export const LIST_STATE3 = getLangTxt('완료');
export const LIST_STATE4 = getLangTxt('실패');
export const LIST_STATE5 = getLangTxt('외주예상금액 미입력');
export const LIST_STATE6 = getLangTxt('상태 3개월 미변경');
export const LIST_STATE7 = getLangTxt('계약 건 미연결');
export const LIST_STATE8 = getLangTxt('추가 정보 미입력');
export const LIST_STATE9 = getLangTxt('외주계약 연결');
export const LIST_STATE10 = getLangTxt('세금계산 미기재');
export const LIST_STATE11 = getLangTxt('입금정보 미기재');
export const LIST_STATE12 = getLangTxt('수주계약 연결');
export const LIST_STATE13 = getLangTxt('지급정보 미기재');
export const LIST_COUNT = getLangTxt('총 {0} 건의 {1}데이터를 표출합니다.');
//영업현황 관리 상세
export const DETAIL_INFO_TITLE1 = getLangTxt('영업단계');
export const DETAIL_INFO_TITLE2 = getLangTxt('영업 정보');
export const DETAIL_INFO_TITLE3 = getLangTxt('관리 파일 등록');
export const DETAIL_INFO_TITLE4 = getLangTxt('주요사항');
export const DETAIL_INFO_TITLE5 = getLangTxt('프로젝트 연결');
export const DETAIL_INFO_TITLE6 = getLangTxt('영업 건 연결');
export const DETAIL_INFO_TITLE7 = getLangTxt('프로젝트 정보');
export const DETAIL_INFO_TITLE8 = getLangTxt('프로젝트 승인상태');
export const DETAIL_INFO_SUB_TITLE1 = getLangTxt('영업코드');
export const DETAIL_INFO_SUB_TITLE2 = getLangTxt('발주처');
export const DETAIL_INFO_SUB_TITLE3 = getLangTxt('담당자');
export const DETAIL_INFO_SUB_TITLE4 = getLangTxt('견적서 첨부');
export const DETAIL_INFO_SUB_TITLE5 = getLangTxt('지역');
export const DETAIL_INFO_SUB_TITLE6 = getLangTxt('예상 계약금액');
export const DETAIL_INFO_SUB_TITLE7 = getLangTxt('계약명/사업명');
export const DETAIL_INFO_SUB_TITLE8 = getLangTxt('예상 외주비율');
export const DETAIL_INFO_SUB_TITLE9 = getLangTxt('실제 계약금액');
export const DETAIL_INFO_SUB_TITLE10 = getLangTxt('예상 외주금액');
export const DETAIL_INFO_SUB_TITLE11 = getLangTxt('실제 외주금액');
export const DETAIL_INFO_SUB_TITLE12 = getLangTxt('예상 순수주금액');
export const DETAIL_INFO_SUB_TITLE13 = getLangTxt('실제 외주비율');
export const DETAIL_INFO_SUB_TITLE14 = getLangTxt('비고');
export const DETAIL_INFO_SUB_TITLE15  = getLangTxt('실제 순수주금액');
export const DETAIL_INFO_SUB_TITLE16  = getLangTxt('프로젝트 코드');
export const DETAIL_INFO_SUB_TITLE17 = getLangTxt('유지보수 여부');
export const DETAIL_INFO_SUB_TITLE18 = getLangTxt('공개 여부');
export const DETAIL_INFO_SUB_TITLE19 = getLangTxt('업무 히스토리 관리');
export const DETAIL_INFO_SUB_TITLE20 = getLangTxt('실 계약금액');
export const DETAIL_INFO_SUB_TITLE21 = getLangTxt('실 외주금액');
export const DETAIL_INFO_SUB_TITLE22 = getLangTxt('순수주금액');
export const DETAIL_INFO_SUB_TITLE23 = getLangTxt('순외주비율');

//영업단계
export const STATE_TITLE1 = getLangTxt('실패');
export const STATE_TITLE2 = getLangTxt('기회');
export const STATE_TITLE3 = getLangTxt('고려');
export const STATE_TITLE4 = getLangTxt('적극');
export const STATE_TITLE5 = getLangTxt('확실');
export const STATE_TITLE6 = getLangTxt('수주대기');
export const STATE_TITLE7 = getLangTxt('수주');
export const STATE_TEXT1 =  getLangTxt('사업 수주에 실패했어요.');
export const STATE_TEXT2 =  getLangTxt('발주처에서 우리 회사에 업무 협의 또는 소개자료를 요청했어요.');
export const STATE_TEXT3 =  getLangTxt('우리 회사를 수행 가능 업체로 인지, 비교자료나 설계서를 요청받았어요.');
export const STATE_TEXT4 =  getLangTxt('주기적인 업무협의와 과업지시서, 설계내역 문서들이 전달되고 있어요.');
export const STATE_TEXT5 =  getLangTxt('계약방법, 사업 수행방안, 사업 시기 등 구체적인 사안을 논의하는 중이에요!');
export const STATE_TEXT6 =  getLangTxt('수주 전 원가, 외주비 등을 <br>팀장/본부장/임원 분들이 검토하고 있어요.');
export const STATE_TEXT7 =  getLangTxt('사업 수주에 성공했어요! 발생되는 계약 건 입력, 꼭 기억해 주세요.');
export const STATE_TEXT_INFO = getLangTxt('영업단계에 따라 상태를 변경해 주세요<br>예상 외주금액은 누락되지 않도록 꼭! 입력해 주세요');

//계약단계
export const CONT_STATE_TITLE1 = getLangTxt('계획');
export const CONT_STATE_TITLE2 = getLangTxt('계약완료');
export const CONT_STATE_TITLE3 = getLangTxt('진행');
export const CONT_STATE_TITLE4 = getLangTxt('검사검수');
export const CONT_STATE_TITLE5 = getLangTxt('발행');
export const CONT_STATE_TITLE6 = getLangTxt('완료');
export const CONT_STATE_TEXT1 =  getLangTxt('계약이 진행되기 전 대기상태에요.계약이 진행되고 있는지 확인해주세요.');
export const CONT_STATE_TEXT2 =  getLangTxt('계약이 완료되어 계약서가 생성되었어요!계약서 파일을 업로드 해주세요.');
export const CONT_STATE_TEXT3 =  getLangTxt('사업이 진행되고 있어요.선금이 청구되었을 수도 있으니 확인해 주세요.');
export const CONT_STATE_TEXT4 =  getLangTxt('준공 단계에서 검수를 하고 있어요.외주업체가 과업을 제대로 이행했는지 확인하세요.');//외주계약
export const CONT_STATE_TEXT7 =  getLangTxt('준공 단계에서 검수를 하고 있어요.검수조서나 진행내역을 입력해 주세요.');//수주계약
export const CONT_STATE_TEXT5 =  getLangTxt('세금계산서 발행이 완료되어 입급/지금을 기다리고 있는 상태에요.');
export const CONT_STATE_TEXT6 =  getLangTxt('입금/지급이 완료되었어요.');
export const CONT_STATE_TEXT_INFO = getLangTxt('진행 상태에 따라 상태를 변경해주세요.<br>검수,세금계산서 발행,입금/지급 확인이 누락되지 않도록<br>꼭! 주기적으로 확인해 주세요.');

//프로젝트 관리 상세
export const SALES_INFO_TITLE1 = getLangTxt('예상금액 및\n 실제 계약금액');
export const DETAIL_DEPARTMENT = getLangTxt('사업부');
export const DETAIL_PROJ_NAME = getLangTxt('프로젝트명');
export const DETAIL_PROJ_PERIOD = getLangTxt('프로젝트 기간');

//파일 업로드
export const UPLOAD_TEXT = getLangTxt('파일을 마우스로 끌어 오세요.');


//데이터 없음
export const EMPTY_DATA = getLangTxt('데이터가 존재하지 않습니다.');


//권한관리
export const AUTH_TITLE1 = getLangTxt('권한그룹');
export const AUTH_TITLE2 = getLangTxt('메뉴별 권한');
export const AUTH_DEP_TITLE1 = getLangTxt('그룹리스트');
export const AUTH_DEP_TITLE2 = getLangTxt('권한명1');
export const AUTH_DEP_TITLE3 = getLangTxt('권한명2');
export const AUTH_DEP_TITLE4 = getLangTxt('권한명3');
export const AUTH_BTN1 = getLangTxt('권한 저장');
export const AUTH_BTN2 = getLangTxt('+ 그룹 추가');



/* 통합검색 */
export const SEARCH_TITLE1 = getLangTxt('계약현황');
export const SEARCH_TITLE2 = getLangTxt('작성내용');
export const SEARCH_TITLE3 = getLangTxt('파일');

//마이페이지
export const MYPAGE_HEAD_TITLE = getLangTxt('승인요청 프로젝트');
export const MYPAGE_TOOL_TIP = getLangTxt('외주비용과 영업수수료를 제외한 원가(순수주) 금액의 비율입니다');